<script>
import MetisMenu from 'metismenujs/dist/metismenujs';

import { getAuth } from '../features/auth/auth.helpers';
import { i18n } from '../main';
const { acl, appVersion } = getAuth();

export default {
  data() {
    return {
      arrow: false,
      acl: acl,
      appVersion: appVersion
    };
  },

  mounted: function () {
    new MetisMenu('#side-menu');
    const links = document.getElementsByClassName('side-nav-link-ref');
    let matchingMenuItem = null;
    const paths = [];

    for (let i = 0; i < links.length; i++) {
      paths.push(links[i]['pathname']);
    }
    const itemIndex = paths.indexOf(window.location.pathname);
    if (itemIndex === -1) {
      const strIndex = window.location.pathname.lastIndexOf('/');
      const item = window.location.pathname.substr(0, strIndex).toString();
      matchingMenuItem = links[paths.indexOf(item)];
    } else {
      matchingMenuItem = links[itemIndex];
    }

    if (matchingMenuItem) {
      matchingMenuItem.classList.add('active');
      const parent = matchingMenuItem.parentElement;

      if (parent) {
        parent.classList.add('mm-active');
        const parent2 = parent.parentElement.closest('ul');
        if (parent2 && parent2.id !== 'side-menu') {
          parent2.classList.add('mm-show');

          const parent3 = parent2.parentElement;
          if (parent3) {
            parent3.classList.add('mm-active');
            const childAnchor = parent3.querySelector('.has-arrow');
            const childDropdown = parent3.querySelector('.has-dropdown');
            if (childAnchor) childAnchor.classList.add('mm-active');
            if (childDropdown) childDropdown.classList.add('mm-active');

            const parent4 = parent3.parentElement;
            if (parent4 && parent4.id !== 'side-menu') {
              parent4.classList.add('mm-show');
              const parent5 = parent4.parentElement;
              if (parent5 && parent5.id !== 'side-menu') {
                parent5.classList.add('mm-active');
                const childanchor = parent5.querySelector('.is-parent');
                if (childanchor && parent5.id !== 'side-menu') {
                  childanchor.classList.add('mm-active');
                }
              }
            }
          }
        }
      }
    }
  },

  computed: {
    menuitem: () => {
      return [
        {
          id: 1,
          label: 'Menu',
          isTitle: true
        },
        {
          id: 2,
          label: 'Home',
          name: 'Home',
          icon: 'bx-home-circle',
          link: '/'
        },
        {
          id: 3,
          label: i18n.tc('backoffice.title'),
          name: 'backoffice_view',
          icon: 'bx-file',
          subItems: [

            {
              id: 9,
              label: 'Uploads',
              name: 'backoffice_edit',
              link: '/planilhaUpload',
              parentId: 3
            },
            {

              id: 5,
              label: 'Registros',
              name: 'backoffice_view',
              subItems: [
                {
                  id: 6,
                  label: 'Visualizar Boletas',
                  name: 'backoffice_view',
                  link: '/parcelaBoletaView',
                  parentId: 3
                },
                {
                  id: 7,
                  label: 'Visualizar Contratos CCEE',
                  name: 'backoffice_view',
                  link: '/parcelaContratoCceeView',
                  parentId: 3
                }
              ]
            },
            {
              id: 8,
              label: 'Validações',
              name: 'backoffice_view',
              link: '/parcelaBoletaValidation',
              parentId: 3
            },
            {
              id: 10,
              label: 'Matching',
              name: 'backoffice_edit',
              link: '/matchingManual',
              parentId: 3
            },
            {
              id: 11,
              label: 'NET Energético',
              name: 'backoffice_edit',
              link: '/netEnergetico',
              parentId: 3
            },
            {
              id: 12,
              label: 'Posição Energética',
              name: 'backoffice_view',
              link: '/PosicaoEnergetica',
              parentId: 3
            }
          ]
        },
        {
          id: 19,
          label: i18n.tc('dias_uteis.title'),
          name: 'feriados-view',
          icon: 'bx-file',
          subItems: [
            {
              id: 20,
              label: i18n.tc('dias_uteis.holidays'),
              name: 'feriados-view',
              link: '/list-feriados',
              parentId: 19
            }
          ]
        },
        {
          id: 21,
          label: i18n.tc('distribuidoras.title'),
          name: 'feriados-view',
          icon: 'bx-file',
          subItems: [
            {
              id: 22,
              label: i18n.tc('distribuidoras.title'),
              name: 'feriados-view',
              link: '/list-distribuidoras',
              parentId: 21
            }
          ]
        },
        {
          id: 23,
          label: i18n.tc('backoffice.tariff'),
          name: 'feriados-view',
          icon: 'bx-file',
          subItems: [
            {
              id: 24,
              label: i18n.tc('backoffice.tariff'),
              name: 'feriados-view',
              link: '/list-tarifa-resolucao',
              parentId: 3
            },
            {
              id: 25,
              label: i18n.tc('backoffice.tariff_item'),
              name: 'feriados-view',
              link: '/list-tarifa-resolucao-item',
              parentId: 3
            },
            {
              id: 26,
              label: i18n.tc('backoffice.bandeira_tarifaria'),
              name: 'feriados-view',
              link: '/list-bandeiras-tarifarias',
              parentId: 3
            },
            {
              id: 27,
              label: i18n.tc('backoffice.modelo_tributario'),
              name: 'feriados-view',
              link: '/list-modelo-tributario',
              parentId: 3
            }
          ]
        },
        {
          id: 28,
          label: 'CCEE',
          name: 'medidas_view',
          icon: 'bx-file',
          subItems: [
            {
              id: 29,
              label: i18n.tc('backoffice.ccee_service'),
              name: 'medidas_edit',
              link: '/ccee-service',
              parentId: 3
            },

            {
              id: 32,
              label: i18n.tc('backoffice.coleta_medidas'),
              name: 'medidas_edit',
              link: '/coleta-medidas',
              parentId: 3
            },
            {
              id: 34,
              label: 'MED5m',
              name: 'medidas_view',
              subItems: [
                {
                  id: 30,
                  label: i18n.tc('backoffice.s3'),
                  name: 'medidas_edit',
                  link: '/s3_5minutos',
                  parentId: 3
                },
                {
                  id: 31,
                  label: i18n.tc('backoffice.qualidadeMedicoes'),
                  name: 'medidas_view',
                  link: '/qualidade-medicoes-5min',
                  parentId: 3
                }
              ]
            },
            {
              id: 34, // Novo ID para a subdivisão MED5m, único
              label: 'MED1h', // Nome da subdivisão
              name: 'medidas_view', // Nome simbólico para permissões, se necessário
              subItems: [
                {
                  id: 33,
                  label: i18n.tc('backoffice.qualidadeMedicoes'),
                  name: 'medidas_view',
                  link: '/qualidade-medicoes-1hora',
                  parentId: 3
                }
              ]
            }
          ]
        },
        {
          id: 40,
          label: i18n.tc('backoffice.companies'),
          name: 'empresas-view',
          link: '/list-empresas',
          icon: 'bx-file'
        }
      ];
    }
  },

  methods: {
    hasItems(item) {
      return item.subItems !== undefined ? item.subItems.length > 0 : false;
    },
    hasPermission(item, permissions) {
      return (
        item.name !== undefined &&
        permissions !== undefined &&
        permissions.includes(item.name)
      );
    }
  }
};
</script>

<template>
  <div id="sidebar-menu">
    <span style="font-size: 10px" class="text-center p-4 float-right">
      V{{ appVersion }}</span>
    <ul id="side-menu" class="metismenu list-unstyled">
      <template v-for="item in menuitem">
        <li class="menu-title" v-if="item.isTitle" :key="item.id">
          {{ item.label }}
        </li>
        <li v-for="itemAcl in acl" v-if="!item.isTitle && !item.isLayout && itemAcl === item.name" :key="item.id">
          <a v-if="hasItems(item)" href="javascript:void(0);" class="is-parent"
            :class="{ 'has-arrow': !item.badge, 'has-dropdown': item.badge }">
            <i :class="`bx ${item.icon}`" v-if="item.icon"></i>
            <span>{{ item.label }}</span>
            <span :class="`badge badge-pill badge-${item.badge.variant} float-right`" v-if="item.badge">{{
        item.badge.text }}</span>
          </a>

          <router-link :to="item.link" v-if="!hasItems(item)" class="side-nav-link-ref">
            <i :class="`bx ${item.icon}`" v-if="item.icon"></i>
            <span>{{ item.label }}</span>
            <span :class="`badge badge-pill badge-${item.badge.variant} float-right`" v-if="item.badge">{{
        item.badge.text }}</span>
          </router-link>

          <ul v-if="hasItems(item)" class="sub-menu" aria-expanded="false">
            <li v-for="(subitem, index) of item.subItems" :key="index">
              <router-link :to="subitem.link" v-if="!hasItems(subitem) && hasPermission(subitem, acl)"
                class="side-nav-link-ref">
                {{ subitem.label }}</router-link>
              <a v-if="hasItems(subitem)" class="side-nav-link-a-ref has-arrow" href="javascript:void(0);">
                {{ subitem.label }}</a>
              <ul v-if="hasItems(subitem)" class="sub-menu mm-collapse" aria-expanded="false">
                <li v-for="(subSubitem, index) of subitem.subItems" :key="index">
                  <router-link :to="subSubitem.link" v-if="!hasItems(subSubitem) && hasPermission(subSubitem, acl)
        " class="side-nav-link-ref">{{ subSubitem.label }}</router-link>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </template>
    </ul>
  </div>
</template>
